import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import moment from "moment";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfContent from "@/vue/domain/content/df-content";

@Component({
  filters: {
    dateFormat(value: Date | string): string {
      return value != null
        ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(
            Utils.DATE_STRING_PATTER
          )
        : "";
    },
  },
})
export default class DfNewsCardComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: () => ({}) })
  news!: DfContent;

  get newsPreviewStyle(): any {
    const style: any = {};
    const newsPreview = Utils.getPropertyImageUrl(
      this.news,
      Utils.PROPERTY_PREVIEW,
      null
    );

    if (newsPreview) {
      style.backgroundImage = `url('${newsPreview}')`;
      style.backgroundPosition = `center center`
    }

    return style;
  }

  get newsTitle(): string {
    return Utils.getPropertyValue(this.news, Utils.PROPERTY_TITLE, "STRING");
  }

  get newsDate(): string {
    return Utils.getPropertyValue(this.news, Utils.PROPERTY_DATE, "DATE");
  }

  private onClick() {
    return this.$emit("newsCardClick", this.news);
  }

  get extraLogo(): string {
    return Utils.getPropertyImageUrl(this.news, Utils.PROPERTY_EXTRA_LOGO, null);
  }
}
